var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-2",attrs:{"dense":"","headers":_vm.headersHistory,"items":_vm.items,"items-per-page":20,"options":_vm.options,"server-items-length":_vm.totalLine,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.loading,"footer-props":{'items-per-page-options':[5, 10, 20, 50, 100]}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"md":3}},[_c('v-select',{attrs:{"prepend-icon":"mdi-account","label":_vm.$t('label.users'),"items":_vm.users,"hide-details":"","clearable":"","multiple":"","attach":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" +"+_vm._s(_vm.selectedUsers.length - 2)+" "+_vm._s(_vm.$t('label.others'))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1),_c('v-col',{attrs:{"md":3}},[_c('v-select',{attrs:{"prepend-icon":"mdi-view-dashboard","label":_vm.$t('label.workspaces'),"items":_vm.workspaces,"hide-details":"","clearable":"","multiple":"","attach":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 5)?_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 5)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" +"+_vm._s(_vm.selectedWorkspaces.length - 5)+" "+_vm._s(_vm.$t('label.others'))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedWorkspaces),callback:function ($$v) {_vm.selectedWorkspaces=$$v},expression:"selectedWorkspaces"}})],1),_c('v-col',{attrs:{"md":3}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":290,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedDateRange,"label":_vm.$t('label.dates'),"prepend-icon":"mdi-calendar","hide-details":"","readonly":""},on:{"click:clear":function($event){_vm.dateRange = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateRange),callback:function ($$v) {_vm.menuDateRange=$$v},expression:"menuDateRange"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":function($event){_vm.menuDateRange = false}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)],1)],1)],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.renderDate(item.date))}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.action)}})]}},{key:"item.folder",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("str_limit")(item.folder,20))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.folder)+" ")])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"cursor",on:{"click":function($event){return _vm.addFilter('user', item.user)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.user)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('help.add_to_filter'))+" ")])])]}},{key:"item.workspace",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"cursor",on:{"click":function($event){return _vm.addFilter('workspace', item.workspace)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.workspace)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('help.add_to_filter'))+" ")])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }